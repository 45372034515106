import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { PostListItemType } from 'types/PostItem.types';
import Template from 'components/templates/MainTemplate';
import MainContainer from 'components/molecules/container/MainContainer';
import MainContent from 'components/molecules/container/MainContent';
import Header from 'components/organisms/header/Header';
import CategoryList from 'components/organisms/blog/CategoryList';
import AlgorithmList from 'components/organisms/blog/AlgorithmList';
import Footer from 'components/organisms/footer/Footer';

interface BlogPageType {
  data: {
    allMarkdownRemark: {
      edges: PostListItemType[];
      group: [
        {
          fieldValue: string;
          totalCount: number;
        },
      ];
    };
    site: {
      siteMetadata: {
        title: string;
        description: string;
        image: string;
        siteUrl: string;
      };
    };
    file: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
      publicURL: string;
    };
  };
}

export default function BlogPage({ data }: BlogPageType) {
  const { siteUrl } = data.site.siteMetadata;

  return (
    <Template url={`${siteUrl}blog`}>
      <MainContainer>
        <Header />
        <MainContent>
          <CategoryList data={data} />
          <AlgorithmList data={data} />
          <Footer />
        </MainContent>
      </MainContainer>
    </Template>
  );
}

export const blogQuery = graphql`
  query blogQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title, frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            categories
          }
        }
      }
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`;
