import styled from '@emotion/styled';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { PostListItemType } from 'types/PostItem.types';
import { mobile } from 'styles/responsive';
import ContentWrapper from 'components/molecules/container/ContentWrapper';
import ContentTitle from 'components/atoms/Text/ContentTitle';
import AlgorithmItem from '../../molecules/items/AlgorithmItem';

export interface AlgorithmListType {
  data: {
    allMarkdownRemark: {
      edges: PostListItemType[];
    };
  };
}

const AlgorithmItemWrapper = styled.div`
  padding: 50px;
  border-radius: 10px;
  background: var(--point-bg);

  hr {
    margin: 50px 0;
    border: none;
    border-top: 2px dashed var(--md-dots);
  }

  @media (max-width: ${mobile}) {
    width: 100%;
    padding: 20px;

    hr {
      margin: 30px 0;
    }
  }
`;

export default function AlgorithmList({ data }: AlgorithmListType) {
  return (
    <ContentWrapper>
      <ContentTitle
        icon={faCubes}
        title={`알고리즘 모아보기`}
        subTitle={`프로그래머스, 백준 알고리즘 정복`}
      />
      <AlgorithmItemWrapper>
        <AlgorithmItem data={data} level={1} />
        <hr />
        <AlgorithmItem data={data} level={2} />
        <hr />
        <AlgorithmItem data={data} level={3} />
      </AlgorithmItemWrapper>
    </ContentWrapper>
  );
}
