import styled from '@emotion/styled';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { mobile, tabletL } from 'styles/responsive';
import ContentWrapper from 'components/molecules/container/ContentWrapper';
import ContentTitle from 'components/atoms/Text/ContentTitle';
import CategoryItem from 'components/molecules/items/CategoryItem';

export interface CategoryListType {
  data: {
    allMarkdownRemark: {
      group: [
        {
          fieldValue: string;
          totalCount: number;
        },
      ];
    };
  };
}

const CategoryListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;

  @media (max-width: ${tabletL}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    margin: 0;
  }
`;

export default function CategoryList({
  data: {
    allMarkdownRemark: { group },
  },
}: CategoryListType) {
  return (
    <ContentWrapper>
      <ContentTitle icon={faBars} title={`Category`} />
      <CategoryListWrapper>
        {group &&
          group.map((category) => {
            return (
              <CategoryItem
                key={category.fieldValue}
                name={category.fieldValue}
                count={category.totalCount}
              />
            );
          })}
      </CategoryListWrapper>
    </ContentWrapper>
  );
}
