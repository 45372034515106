import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { ReactNode } from 'react';
import { mobile, tabletL } from 'styles/responsive';
import { PostListItemType } from 'types/PostItem.types';

interface AlgorithmItemType {
  data: {
    allMarkdownRemark: {
      edges: PostListItemType[];
    };
  };
  level: number;
}

interface GatsbyLinkType {
  children: ReactNode;
  className?: string;
  to: string;
}

const LevelText = styled.p`
  grid-column: 1 / 4;
  margin-bottom: 20px;
  color: var(--point-color);
  font-size: 24px;
  font-weight: 600;
`;

const LevelList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px 30px;

  @media (max-width: ${tabletL}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${mobile}) {
    display: block;
  }
`;

const AlgorithmPost = styled(({ ...props }: GatsbyLinkType) => (
  <Link {...props} />
))`
  color: var(--post-point-text);
  font-size: 20px;

  &:hover {
    color: var(--point-color);
  }

  @media (max-width: ${mobile}) {
    display: block;
    margin: 15px 0;
    font-size: 16px;
  }
`;

export default function AlgorithmItem({ data, level }: AlgorithmItemType) {
  const { edges } = data.allMarkdownRemark;

  return (
    <div>
      <LevelText>Level {level}</LevelText>
      <LevelList>
        {edges
          .filter(
            ({ node: { frontmatter } }: PostListItemType) =>
              frontmatter.categories === 'Algorithm' &&
              frontmatter.title.includes(`Lv.${level}`),
          )
          .map(
            ({
              node: {
                fields: { slug },
                frontmatter,
              },
            }: PostListItemType) => (
              <AlgorithmPost to={slug} key={frontmatter.title}>
                {frontmatter.title.split('-', 2)[1]}
              </AlgorithmPost>
            ),
          )}
      </LevelList>
    </div>
  );
}
