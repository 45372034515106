import styled from '@emotion/styled';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import { mobile, tabletL } from 'styles/responsive';

export interface CategoryItemType {
  name: string;
  count: number;
}

const ItemWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 30px auto;
  transition: 0.3s box-shadow;
  cursor: pointer;

  @media (max-width: ${mobile}) {
    margin-bottom: 0;
  }
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 200px;
  padding: 40px;
  border-radius: 10px;
  background: var(--point-bg);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: ${tabletL}) {
    height: 150px;
    padding: 32px;
  }

  @media (max-width: ${mobile}) {
    height: 120px;
    padding: 24px;
  }
`;

const CategoryData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px 10px;

  h3 {
    color: var(--post-point-text);
    font-size: 20px;
  }

  h4 {
    color: var(--text-color);
  }

  @media (max-width: ${mobile}) {
    h3 {
      font-size: 18px;
    }
  }
`;

export default function CategoryItem({ name, count }: CategoryItemType) {
  return (
    <ItemWrapper to={`/categories/${kebabCase(name)}/`} key={name}>
      <Thumbnail>
        <img src={`/${kebabCase(name)}.png`} alt={`${name} thumbnail`} />
      </Thumbnail>
      <CategoryData>
        <h3>{name}</h3>
        <h4>{count} Posts</h4>
      </CategoryData>
    </ItemWrapper>
  );
}
